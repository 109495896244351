import styled from "@emotion/styled"
import React from "react"
import { H1 } from "../components/header"
import { Page } from "../components/page"
import { SinclairCalculator } from "../components/sinclair"
import { InfoBlock } from "../components/widgets/info"

const Description = styled.p`
  margin-bottom: 0;
`

const CalculatorInfo = styled(InfoBlock)`
  margin-top: 4.45rem;
  font-size: 80%;
`

const SinclairCalculatorPage = () => {
  return (
    <Page
      seoTitle="Sinclair calculator"
      seoDescription="Sinclair calculator for Olympic Weightlifting"
      header={() => (
        <>
          <H1>Sinclair calculator</H1>
          <Description>For Olympic Weightlifting</Description>
        </>
      )}
    >
      <SinclairCalculator />

      <CalculatorInfo>
        <p>
          This calculator allows you to check what would be your total lifted
          weight if you were competing in the heaviest category with your
          current level of ability. It can be used to compare athletes` totals
          across different weight categories in men's and women's Olympic
          Weightlifting.
        </p>
        <p>
          Based on{" "}
          <a href="https://iwf.sport/wp-content/uploads/downloads/2017/01/Sinclair_Coefficients_2017.pdf">
            The Sinclair Coefficients for the Olympiad Jan 1, 2017 to Dec 31,
            2020
          </a>
        </p>
      </CalculatorInfo>
    </Page>
  )
}

export default SinclairCalculatorPage
